<template>
  <div class="container">
    <div class="containCenter">
      <sz-mode szType="grid" :bg="'left_bg'" position="left-top" width="23.5%" height="100%" lineShow="true">
        <template #grid>
          <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </template>
      </sz-mode>
    </div>
    <div id="container" class="divwxMap" style="width: 100%; height:100%"></div>

    <cun-mode ref="cunMode"></cun-mode>
    <!-- 网格员详细展示以及图标详细展示 -->
    <wg-mode ref="wgMode"></wg-mode>

    <EZUIKitJs ref="ezuik" />
    <t-footer @tab="handlerTab"></t-footer>
  </div>
</template>

<script>
import WgMode from '@/components/wgMode.vue'
import tFooter from '@/components/footer.vue'
import CunMode from '@/components/cunMode.vue'
import {selectTree, getOthers, getHisItems, selectGroupCamera, } from '@/api/api.js'
import { getID } from '@/utils/index.js'
import EZUIKitJs from '@/components/EZUIKitJs.vue'
export default {
  components: {
    tFooter,
    CunMode,
    WgMode,
    EZUIKitJs
  },
  data() {
    return {
      wxMap: null,
      tianInfo: {},
      year: '',
      baseForm: {},
      treeData: [
        {
          label: '小平房1组',
          children: [
            {
              label: 'STX-001',
              url:"ezopen://TDIXPT@open.ys7.com/D00500921/1.live",
            },
            {
              label: 'STX-002',
              url:"ezopen://TDIXPT@open.ys7.com/D00500921/2.live",
            }, 
            {
              label: 'STX-003',
            }, 
            {
              label: 'STX-004',
            }, 
            {
              label: 'STX-005',
            }, 
            {
              label: 'STX-006',
            }, 
            {
              label: 'STX-007',
            }, 
            {
              label: 'STX-008',
            }, 
            {
              label: 'STX-009',
            }
          ]
        }, 
        {
          label: '文化中心',
          children: [{
            label: '测试',
          }]
        } 
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted() {
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.$nextTick(() => {
      this.getTree()
      this.initMap()
      this.getDetail()
    })
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    },
    getItems() {
      getHisItems({ villageId: this.baseForm.regionId, historyType: 0, page: 1, rows: 10000 }).then(res => {
        if (res.code == '0') {
          if (res.items.length > 0) {
            res.items.map(item => {
              if (item.historyDate) item.historyDate = this.$moment(item.historyDate).format('YYYY-MM-DD')
            })
          }
          this.cunHis = res.items
        }
      })
    },
    //网格员详细信息展示
    goDetail(item) {
      let data = item
      data.itemtype = 'grider'

      this.$refs.wgMode.initData(data)
      this.$refs.wgMode.wgList = [item]
    },
    //详情
    goDetailInfo(item) {
      this.$refs.cunMode.initData(item)
    },
    getDetail() {
      this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectGroupCamera({villageId: this.baseForm.regionId}).then(res=>{
        if (res.code == '0') {
          let data = ''
          if (res.model.length) {
            res.model.map(item => {
              item.lgt = item.longitude
              item.lat = item.latitude
              item.mode = true
              item.type = 'videoType'
              // gridding --绘制区域需要
              if (item.introduction) item.mode = true
              item.icon = 'dapeng'
            })
          }
          data = res.model
          this.setCunMap(data)
        }
      })
      // getpqDetail({ villageId: this.baseForm.regionId, page: 1, rows: 10000 }).then(res => {
      //   if (res.code == '0') {
      //     let data = ''
      //     if (res.items.length) {
      //       res.items.map(item => {
      //         item.lgt = item.longitude
      //         item.lat = item.latitude
      //         item.mode = true
      //         item.type = 'videoType'
      //         // gridding --绘制区域需要
      //         if (item.introduction) item.mode = true
      //         item.icon = 'dapeng'
      //       })
      //     }
      //     data = res.items
      //     this.setCunMap(data)
      //   }
      // })
    },
    getTree() {
      this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      selectTree({villageId: this.baseForm.regionId}).then(res=>{
        console.log(res.model)
        console.log(this.treeData)
        // this.treeData = res.model
      })
    },

    initMap() { 
      // vue项目需要先声明 T = window.T，不然后面无法获取到。
      let T = window.T
      this.wxMap = null
      let initMap = new T.Map('container') // div的id
      // 传参中心点经纬度，以及放大程度，最小1，最大18
      initMap.centerAndZoom(new T.LngLat(Number(this.baseForm.longitude), Number(this.baseForm.latitude)), 13.5)
      initMap.setStyle('black')
      initMap.enableDrag()
      initMap.enableScrollWheelZoom()
      this.wxMap = initMap
      //
    },

    setCunMap(data) {
      if (typeof data == 'string') {
        let list = []
        console.log(this.dataList)

        this.dataList.forEach(item => {
          if (item.detailList.length > 0) {
            item.detailList.forEach(its => {
              if (its.elementValue.elementType == 'media' && its.elementValue.lat) {
                list.push(its)
              }
              if (its.elementValue.elementType == 'checkBox' && its.elementValue.checkList.length > 0) {
                its.elementValue.checkList.map(itss => {
                  if (itss.list.length > 0) {
                    list = list.concat(itss.list)
                  }
                })
              }
            })
          }
        })
        data = list
        console.log(data)
        this.setMapSetting(data)
        this.setRegion(data)
      } else {
        this.setMapSetting(data)
        this.setRegion(data)
      }
    },
    filterArr(list) {
      let arr = list
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].detailList && arr[i].detailList.length) {
          arr[i].detailList.map(item => {
            if (item?.elementValue) {
              item.elementValue = JSON.parse(item.elementValue)
              if (item.elementValue.elementType == 'checkBox') {
                // 多选
                item.elementValue.checkList.forEach(el => {
                  el.id = getID()
                })
              }
              if (item.elementValue.elementType == 'media') {
                // 带有监控
                item.elementValue.list = []
                item.elementValue.list.push({
                  icon: 'dapeng',
                  lat: item.elementValue.lat,
                  lgt: item.elementValue.lgt,
                  name: item.elementValue.title,
                  articleContent: item.elementValue.articleContent
                })
              }
              if (item.elementValue.elementType == 'morelines') {
                //多折线
                item.elementValue.legend = []
                item.elementValue.yAxisData.map(it => {
                  item.elementValue.legend.push(it.name)
                  it.data = []
                  if (it.tagData.length) {
                    it.tagData.map(ii => {
                      if (ii?.num != undefined) {
                        it.data.push(ii.num)
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
      return arr
    },
    //菜单详情
    getDetatils(stid, parentId) {
      getOthers({ stId: stid, parentId: parentId ? parentId : '' }).then(res => {
        if (res.code == '0') {
          this.dataList = this.filterArr(res.model)
          this.$nextTick(() => {
            this.clearMarker()
            this.setCunMap('ls')
            this.changerow = ''
          })
        }
      })
    },
    handlerTab(index) {
      this.$nextTick(() => {
        if (index != this.num) {
          this.num = index
          this.changeTab(index)
        }
      })
    },
  },
  created() {}
}
</script>

<style lang="scss" scped>
@import '../common/css/chart';
:root {
  --color: rgba(12, 89, 240, 0.7);
  --lineColor: rgba(102, 163, 224, 0.2);
}
.containCenter {
  .c_left {
    position: fixed;
    left: 40px;
    width: 23.5%;
  }
  .c_right {
    position: fixed;
    right: 40px;
    width: 23.5%;
  }
  .panel_item {
    margin: 5px 12px;
    .panel_title {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    }
    .center_title {
      text-align: center;
    }
  }
  .flex_item {
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    .flex_item_col {
      width: 30%;
      margin: 10px 0;
      flex: 1;
      color: #fff;
    }
    .flex_item_num {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
    }
  }
  .member {
    height: 105px;
    overflow-y: auto;
  }
  .member_mes {
    width: 100%;
    justify-content: space-evenly !important;
    margin: 2px 0;
    cursor: pointer;
  }
  /*网格员姓名 */
  .grid_member .member_mes .member_full_name {
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* color: #FFFFFF; */
  }

  /*网格员电话 */
  .grid_member .member_mes .member_mobile {
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* color: #FFFFFF; */
  }
  .his_list_item {
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
  .list_item_title {
    width: 100%;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .list_item_date {
    font-size: 16px;
    text-align: right;
    color: #ccc;
  }
}
.word table {
  width: 100% !important;
  box-sizing: border-box !important;
  border-right: 1px solid #d1d1d1 !important;
  border-bottom: 1px solid #d1d1d1 !important;
  border-spacing: 0px !important;
  tbody {
    border-spacing: 0px !important;
  }
  tbody tr:nth-child(1) {
    text-align: center !important;
  }
  th {
    padding: 4px !important;
    font-size: 15px !important;
    font-weight: bold !important;
    border: 1px solid #d1d1d1 !important;
  }
  tr {
    border-color: inherit !important;
    border: 1px solid #d1d1d1 !important;
  }
  td {
    padding: 4px !important;
    font-size: 14px !important;
    border-top: 1px solid #d1d1d1 !important;
    border-left: 1px solid #d1d1d1 !important;
    text-align: center !important;
  }
}
.el-tree{
  font-size: larger;
  color: #FFFFFF;// 白色字体
  background: rgba(0,0,0,0);// 整体背景透明
  .el-checkbox__inner{
      background:rgba(0,0,0,0);// checkbox 背景透明
  }
  .el-tree-node__content:hover {
      background: rgba(104,147,215,0.4);// hover 节点背景色，否则是白色
  }
  .el-tree-node:focus>.el-tree-node__content {
      background-color: rgba(0,0,0,0); // focus 节点背景透明，否则是白色
  }
}
</style>
